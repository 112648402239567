var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalBase',{attrs:{"name":"restrictUsersModal","size":"medium","clickToClose":false,"showClose":true,"title":"Restrict Users","loading":_vm.makingApiRequest,"position":"center","bottomButtonPrimary":{
    key: 'save',
    disabled: !_vm.showSubmitButton,
    label: 'Save'
  },"bottomButtonSecondary":{
    key: 'cancel',
    label: 'Cancel'
  }},on:{"primaryButtonClick":_vm.restrictSelectedUsers,"closed":_vm.hideModal}},[_c('ListView',{attrs:{"data":_vm.selectedUsers,"paginationLinks":{},"tableColumns":_vm.tableColumns,"showSelectable":false,"showPagination":false}}),_c('form-builder',{ref:"formElement",staticClass:"mt-20",attrs:{"schemas":_vm.fields,"formValues":_vm.restrictData || {}},on:{"formFieldChanged":_vm.formFieldChangeHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }