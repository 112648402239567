
import Vue from "vue";

import { userMapActions, userMapState } from "@/store/modules/user";
import * as dateFns from "date-fns";
import { restrictedUser } from "@/forms/admin/restrictUser";
import { RestrictUserFields } from "@/store/modules/user/types";
import { ITableWrapperColumns } from "@/types";
import { tableColumnsListUsers } from "./TableColumns";

interface IListUsersData {
  restrictData: { level: number; startDate: Date; endDate: Date };
  loading: boolean;
  fields: any;
  tableColumns: ITableWrapperColumns;
}

export default Vue.extend({
  name: "restrict-users",
  props: {
    selectedUsers: {
      type: Array,
      required: true
    }
  },
  data(): IListUsersData {
    return {
      loading: false,
      restrictData: { level: 0, startDate: new Date(), endDate: new Date() },
      fields: restrictedUser,
      tableColumns: tableColumnsListUsers
    };
  },
  methods: {
    ...userMapActions(["getUsers", "banUsers", "restrictUsers"]),
    restrictSelectedUsers() {
      this.$emit("restrictSelectedUsers", this.restrictData);
    },
    formFieldChangeHandler({
      key,
      value
    }: {
      key: RestrictUserFields;
      value: any;
    }): void {
      this.restrictData[key] = value;
    },
    hideModal() {
      this.$emit("hideModal");
    }
  },
  computed: {
    ...userMapState(["makingApiRequest"]),
    showSubmitButton() {
      if (
        dateFns.isAfter(this.restrictData.endDate, this.restrictData.startDate)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
});
